import React, { useContext, useEffect } from "react"
import { TranslatorContext } from "../../context/Translator"
import { ButtonComponent, AnchorComponent } from "../../components/elements"
import IconFieldComponent from "../../components/fields/IconFieldComponent"
import LogoComponent from "../../components/LogoComponent"
import { checkTokenExpiry, storeToken } from "../../utils/tokenUtils"
import { loginValidationSchema } from "../../utils/validationSchema"
import { useFormik } from "formik"
import { LoginInitialValues as initialValues } from "../../utils/type"
import {
  emitErrorNotification,
  getErrorMessage,
  emitNotification,
} from "../../utils/helper"
import { useNavigate } from "react-router-dom"
import { loginService } from "../../services/main"

export default function LoginPage() {
  const navigate = useNavigate()
  const { t } = useContext(TranslatorContext)
  const [loading, setLoading] = React.useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        let formData = { username: values?.email, password: values?.password }
        const response = await loginService(formData)
        if (response?.data?.status) {
          storeToken(response?.data?.data?.token)
          emitNotification("success", "Login successful!")
          navigate("/dashboard")
        } else {
          emitErrorNotification("Something went wrong. Please try again.")
        }
      } catch (error) {
        emitErrorNotification(getErrorMessage(error))
      } finally {
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (checkTokenExpiry()) {
      navigate("/dashboard")
    }
  }, [])

  return (
    <div className="mc-auth">
      <img
        src="images/pattern.webp"
        alt="pattern"
        className="mc-auth-pattern"
      />
      <div className="mc-auth-group">
        <LogoComponent
          src="images/logo.webp"
          alt="logo"
          href="/ecommerce"
          className="mc-auth-logo"
        />
        <h4 className="mc-auth-title">Login to MAMM</h4>
        <form className="mc-auth-form" onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <IconFieldComponent
              icon="email"
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              classes="w-100 h-sm mb-0"
              placeholder={t("enter_your_email")}
            />
            {formik.errors.email && formik.touched.email && (
              <ErrorMsg msg={formik.errors.email} />
            )}
          </div>
          <div className="mb-3">
            <IconFieldComponent
              icon="lock"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              classes="w-100 h-sm mb-0"
              placeholder={t("enter_your_password")}
              passwordVisible={true}
            />
            {formik.errors.password && formik.touched.password && (
              <ErrorMsg msg={formik.errors.password} />
            )}
          </div>
          {/* <IconFieldComponent
            icon="verified_user"
            classes="w-100 h-sm"
            option={[
              "select user role",
              "admin",
              "member",
              "client",
              "manager",
              "vendor",
            ]}
          /> */}
          <div>
            <ButtonComponent className="mc-auth-btn h-sm" type="submit">
              {loading ? t("Loading...") : t("sign_in")}
            </ButtonComponent>
          </div>
        </form>
      </div>
    </div>
  )
}

const ErrorMsg = ({ msg }) => {
  return <p className="d-flex text-danger">{msg}</p>
}
